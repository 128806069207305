import { useEffect } from "react";
import amplitude from 'amplitude-js';

import styles from "./ChartBanner.module.scss";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import {useNavigate} from "react-router-dom";
import LazyImage from "../../UI/lazyImage/LazyImage";

const ChartBanner = props => {
    const { data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.chartBanner
    const textData = strings.plan.chartBanner
    const navigate = useNavigate();
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
        dataLayer.push({
                            "event": "ga4event",
                            "event_category": "screen_onboarding_plan_is_ready_appeared",
                            "funnel_type": data.campaign,
                            "step": pageInfo.order
                       });
        amplitude.getInstance().logEvent('screen_onboarding_plan_is_ready_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_plan_is_ready_continue_tapped');
        localStorage.setItem('reach_paywall_' + data.campaign, true);
        navigate("/paywall");
    }

    return (
        <div className={mainContainerClass}>
            <div className={styles.imageBox}>
                <img loading="lazy" className={styles.logoImage} src="/images/logo-black.svg" alt="" />
            </div>
            <h1 className={styles.title}>{textData.title}</h1>
            <div className={`${styles.infoBox} flex-column`}>
                <div className={`${styles.chartArea}`}>
                    <LazyImage
                        srcWebp="/images/result/chartBanner.webp"
                        src="/images/result/chartBanner.png"
                        alt=""
                    />
                </div>
                <p className={styles.subtitle}>
                    {textData.subtitle}
                </p>
            </div>
            <p className={styles.remark}>
                <b>{textData.infoBox.percent}</b>
                {textData.infoBox.start}
                <b>{data.campaign === 'cf' ? textData.infoBox.cfInfo : textData.infoBox.info}</b>
                {textData.infoBox.middle}
                <b>{textData.infoBox.last}</b>
            </p>
            <ContinueButton
                text={textData.continue}
                nextStep={clickContinue}
                theme={themeData.continueButton}
            />
        </div>
    )
}

export default ChartBanner;
